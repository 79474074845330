<template lang="pug">
.DeleteButton
	SingaButton.is-small.is-transparent-dark.detail-like.delete-button(icon-left="trash-outline" @click="deleteSong" :disabled="loading")
</template>

<script setup lang="ts">
import SongDeleted from './notifications/SongDeleted.vue'

const { $singaApi, $oruga } = useNuxtApp()

const loading = ref(false)

const props = defineProps({
	playlist: {
		type: Object,
		required: true
	},
	entry: {
		type: Object,
		required: true
	},
	accessPoint: {
		type: String,
		required: true
	}
})

const deleteSong = async () => {
	loading.value = true
	const id = props.playlist.id
	const params = {
		headers: { 'X-HTTP-Method-Override': 'DELETE' },
		body: {
			variants: [{
				variant: props.entry.variant.id,
				positions: [props.entry.position]
			}]
		}
	}
	try {
		await $singaApi.Playlists.deleteVariant(id, params)
		useEventEmit('libraryPlaylists:deletePlaylistSong')
		useEventEmit('libraryPlaylist:updateUserPlaylists', true)
		const lengthCheckedTitle = useTruncate(props.playlist.title)
		const { getArtistSlugs } = useArtistNames(props.entry.song.artists)
		const artists = getArtistSlugs()
		$oruga.notification.open({
			component: SongDeleted,
			props: {
				songLink: `/artists/${artists}/${props.entry.song.slug}/${props.entry.song.hash}`,
				songName: props.entry.song.name,
				playlistTitle: lengthCheckedTitle,
				playlistLink: `/playlists/${props.playlist.slug}/${props.playlist.hash}`
			},
			icon: 'checkmark-outline',
			iconSize: 'medium'
		})
		const userStore = useUserStore()
		const { segmentEvent } = useSegment()

		const playlistData = {
			access_point: props.accessPoint,
			country: userStore.user?.country,
			user_id: userStore.user?.resource_id,
			singlist_id: props.playlist.resource_id,
			song_id: props.entry.id,
			variant_id: props.entry.variant.id
		}
		segmentEvent('Singlist Song Deleted', playlistData)
	} catch (error: any) {
		console.error(error)
	} finally {
		loading.value = false
	}
}
</script>

<style lang="sass" scoped>
.delete-button
	height: 40px
</style>
